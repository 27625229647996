import React from 'react';
import {BrowserRouter as Router, Switch, Route, Link} from "react-router-dom";
import logo from './assets/logo.svg';
import './assets/App.css';
import Loading from "./components/Loading";
import AuthenticationButton from "./components/AuthenticationButton";
import { useAuth0 } from "@auth0/auth0-react";

function App() {
  const { isLoading, error } = useAuth0();

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Router>
      <div className="App">
        <header className="App-header">
        <AuthenticationButton />
        </header>
      </div>
    </Router>
  );
}

export default App;
