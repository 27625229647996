import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Auth0Provider } from "@auth0/auth0-react";
import history from "./utils/history";
import Auth0Config from "./utils/config";
import reportWebVitals from './reportWebVitals';

import './assets/index.css';

const onRedirectCallback = async (appState: any) => {
  history.replace({
    pathname: appState?.returnTo || window.location.pathname,
    search: '',
  });
};

const providerConfig = {
  domain: Auth0Config.auth0Domain,
  clientId: Auth0Config.auth0ClientId,
  ...(Auth0Config.auth0Ident ? { audience: Auth0Config.auth0Ident } : null),
  redirectUri: window.location.origin,
  onRedirectCallback,
};

ReactDOM.render(
  <Auth0Provider {...providerConfig}>
    <App />
  </Auth0Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
